@import '../variables';
@import '../functions';

.services {
  padding: 0 0 150px 0;

  @media (max-width: $mobile) {
    padding: 0;
  }

  &__title {
    font-style: normal;
    font-family: 'Roboto Light', sans-serif;
    font-weight: normal;
    font-size: 48px;
    line-height: 56px;
    text-align: center;
    max-width: 1000px;
    margin: 50px auto;

    @media (max-width: $mobile) {
      max-width: 100%;

      font-size: 28px;
      line-height: 33px;
      margin: 30px 24px 0 24px;
    }
  }

  &__list {
    margin: 30px auto 0 auto;
    padding: 0 12px;
    max-width: 990px;
    list-style: none;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    @media (max-width: $mobile) {
      max-width: initial;
      margin: 30px auto 62px auto;
      padding: 0 24px;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    min-width: 120px;
    max-width: 172px;
    justify-content: flex-start;
    align-items: center;
    margin: 25px 0 50px 40px;

    &:first-of-type {
      margin: 25px 0 50px 0;
    }

    @media (max-width: $mobile) {
      width: 60px;
      margin: 27px 0 0 8px;
      min-width: 80px;
      max-width: auto;

      &:first-of-type {
        margin: 27px 0 0 0;
      }
    }
  }

  &__img {
    @media (min-width: $desktop) {
      background-image: url('/assets/images/services.png');
      background-size: 292px 218px;
      width: 62px;
      height: 62px;
    }

    @media (max-width: $mobile) {
      background-image: url('/assets/images/services.png');
      background-size: 156px 117px;
      width: 34px;
      height: 32px;
    }

    &-avarcom {
      background-position: -225px 0;
    }
    &-expert {
      background-position: -442px -361px;
    }
    &-status {
      background-position: -74px -365px;
    }
    &-tow-truck {
      background-position: -80px 0;
    }
    &-manipulator {
      background-position: -7px 0;
    }
    &-accum {
      background-position: -442px -289px;
    }
    &-gas {
      background-position: -7px -365px;
    }
    &-open {
      background-position: -76px -77px;
    }
    &-wheel {
      background-position: -153px -5px;
    }
    &-penalties {
      background-position: -7px -79px;
    }

    @media (max-width: $mobile) {
      &-avarcom {
        background-position: -121px 0;
      }
      &-expert {
        background-position: -235px -195px;
      }
      &-status {
        background-position: -42px -197px;
      }
      &-tow-truck {
        background-position: -44px -1px;
      }
      &-manipulator {
        background-position: -4px -1px;
      }
      &-accum {
        background-position: -239px -156px;
      }
      &-gas {
        background-position: -3px -199px;
      }
      &-open {
        background-position: -42px -43px;
      }
      &-wheel {
        background-position: -81px -3px;
      }
      &-penalties {
        background-position: -2px -41px;
      }
    }
  }

  &__text {
    font-family: 'Roboto Light', sans-serif;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.41px;
    margin-top: 10px;
    height: 33px;

    @media (max-width: $mobile) {
      font-size: 8.5px;
      line-height: 11px;
      letter-spacing: -0.205px;
    }
  }
}