.footer {
  background: #2E236F;

  &__wrapper {
    width: 1110px;
    margin: 0 auto;
    padding: 72px 0 56px;

    @media (max-width: $mobile) {
      width: 100%;
      padding: 10px 24px;
    }
  }

  &__menu {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media (max-width: $mobile) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__divider {
    margin: 60px 0 42px;
    border: 1px solid #42387B;
    border-bottom: none;
  }

  &__aside {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    @media (max-width: $mobile) {
      flex-direction: column;
    }
  }

  &__logo {
    display: block;
    background-image: url('/assets/images/logo.svg');
    width: 185px;
    height: 44px;

    @media (max-width: $mobile) {
      margin: 16px 0 34px 0;
    }
  }

  &__contact {
    color: #EAE9F1;
    text-decoration: none;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    text-align: right;
    letter-spacing: 0.15px;

    margin-left: auto;

    &:hover {
      color: #fff;
    }

    @media (max-width: $mobile) {
      margin-left: 0;
    }
  }

  &__copyright {
    margin: 0;
    padding: 0;
    color: #A49FBE;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
  }

  &__legal {
    padding: 0;
    color: #A49FBE;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    letter-spacing: -0.1px;

    margin: 0 0 0 auto;

    @media (max-width: $mobile) {
      text-align: left;
      margin: 10px 0;
    }
  }
}