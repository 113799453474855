.download {
  position: relative;
  min-height: 1040px;

  background: radial-gradient(closest-side, #42318D 20%, #715DAD 100%);


  @media (max-width: $mobile) {
    min-height: auto;
  }

  &__content {
    z-index: 2;
    display: flex;
    flex-direction: row;
    position: relative;

    @media (max-width: $mobile) {
      flex-direction: column;
      margin-bottom: 46px;
    }
  }

  &__title {
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 56px;
    text-align: right;

    margin: 580px 0 0 120px;
    padding: 0;
    max-width: 280px;

    @media (max-width: 1270px) {
      margin: 450px 0 0 50px;
    }

    @media (max-width: $mobile) {
      margin: 0 26px 24px;
      max-width: initial;

      font-size: 28px;
      line-height: 33px;
      letter-spacing: -0.3px;
      text-align: left;
      font-family: 'Roboto Light', sans-serif;
    }
  }

  &__options {
    display: flex;
    flex-direction: column;
    margin: 424px 237px 0 auto;

    @media (max-width: 1270px) {
      margin: 400px 100px 0 auto;
    }

    @media (max-width: $mobile) {
      margin: 0 24px;
      flex-direction: row-reverse;
    }
  }

  &__iphones {
    width: 913px;
    height: 968px;

    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    margin: 0 auto;

    background: url('/assets/images/iphones-download.png');
    background-size: cover;

    @media (max-width: $mobile) {
      width: 277px;
      height: 294px;
      position: relative;
    }
  }

  &__qr {
    width: 191px;
    height: 191px;
    background: url('/assets/images/qr.svg');
    background-size: cover;
    margin: 0 48px 0 0;

    @media (max-width: $mobile) {
      width: 92px;
      height: 92px;
      background-size: cover;
      margin: 0;
    }
  }

  &__apps {
    display: grid;
    grid-template-columns: 1fr;

    @media (max-width: $mobile) {
      margin: 0 auto 0 0;
      grid-gap: 13px 12px;
    }

  }

  &__app {
    margin: 24px 0 0 0;

    @media (max-width: $mobile) {
      margin: 0;
    }
  }
}