@import 'normalize.css/normalize.css';
@import 'variables';

@font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/Roboto-Regular.ttf');
}
@font-face {
  font-family: 'Roboto Medium';
  src: url('/assets/fonts/Roboto-Light.ttf');
}
@font-face {
  font-family: 'Roboto Bold';
  src: url('/assets/fonts/Roboto-Bold.ttf');
}
@font-face {
  font-family: 'Roboto Thin';
  src: url('/assets/fonts/Roboto-Thin.ttf');
}
@font-face {
  font-family: 'Roboto Light';
  src: url('/assets/fonts/Roboto-Light.ttf');
}

* {
  box-sizing: border-box;
}

.body {
  background: #715DAD;
  font-family: 'Roboto', sans-serif;
  color: #fff;
}

.screen {
  width: 1440px;
  margin: 0 auto;

  @media (max-width: 1440px) {
    width: 100%;
  }
}

.get-button {
  width: 188px;
  height: 50px;
  display: block;
  cursor: pointer;

  @media (max-width: $mobile) {
    width: 130px;
    height: 40px;
    background-size: cover;
  }

  &-app-store {
    @media (min-width: $desktop) {
      background-image: url('/assets/images/app-store.svg');
    }
    @media (max-width: $mobile) {
      background-image: url('/assets/images/app-store-mobile.svg');
    }
  }
  &-google-play {
    @media (min-width: $desktop) {
      background-image: url('/assets/images/google-play.svg');
    }
    @media (max-width: $mobile) {
      background-image: url('/assets/images/google-play-mobile.svg');
    }
  }
  &-app-gallery {
    @media (min-width: $desktop) {
      background-image: url('/assets/images/app-gallery.svg');
    }
    @media (max-width: $mobile) {
      background-image: url('/assets/images/app-gallery-mobile.svg');
    }
  }
}
