@import 'variables';

.nav {
  width: 100%;
  background: rgba(0, 0, 0, 0.17);
  padding: 18px 25px;
  display: flex;
  align-items: center;

  position: fixed;
  z-index: 5;

  @media (max-width: $mobile) {
    flex-wrap: wrap;

    &:not(.nav-mobile-closed) {
      background: rgba(0, 0, 0, 0.5);
    }
  }


  &-mobile-closed {
    .nav__menu {
      @media (max-width: $mobile) {
        display: none !important;
      }
    }
  }

  &__logo {
    display: block;
    background-image: url('/assets/images/logo.svg');
    width: 185px;
    height: 44px;
    background-size: cover;

    @media (max-width: $mobile) {
      width: 125px;
      height: 30px;
    }
  }

  &__burger {
    display: none;

    @media (max-width: $mobile) {
      display: block;
      margin-left: auto;
      border: none;
      background-image: url('/assets/images/burger.svg');
      background-color: transparent;

      width: 18px;
      height: 12px;
    }
  }

  &__menu {
    display: flex;
    margin-left: auto;
    flex-direction: row;
    align-items: center;
    padding: 12px 7px;

    @media (max-width: $mobile) {
      height: 100vh;
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      padding: 20px 7px;
    }
  }

  &__link {
    text-decoration: none;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #FCC322;
  }
}