@import '../variables';

.benefits {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__divider {
    width: 1110px;
    border: 1px solid #8B7BBC;
    border-bottom: none;
    margin: 0 0 144px 0;
    height: 0;

    @media (max-width: $mobile) {
      width: 100%;
      margin: 0 0 51px 0;
    }
  }
}

.benefit {
  display: flex;
  flex-direction: row;

  @media (max-width: $mobile) {
    margin: 0 24px;
    flex-direction: column-reverse;
  }

  &__title {
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 56px;
    margin: 0 0 43px 0;

    @media (max-width: $mobile) {
      font-size: 28px;
      line-height: 33px;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    max-width: 608px;
    margin-top: 180px;

    @media (max-width: $mobile) {
      margin-top: auto;
    }
  }

  &__content {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    font-family: 'Roboto Light', sans-serif;
    margin: 0;
    max-width: 600px;

    @media (max-width: $mobile) {
      font-size: 1em;
      line-height: 140%;
      max-width: 100%;
    }
  }

  &__iphone {
    width: 531px;
    height: 820px;
    background-image: url('/assets/images/iphone_screen_sprites.png');
    background-size: cover;

    @media (max-width: $mobile) {
      width: 230px;
      height: 355px;
      background-size: cover;
      margin-top: 30px;
    }

    &-fast-reg {
      background-position: 0 calc(0 * #{$iphone-sprite-offset});
    }
    &-geolocation {
      background-position: 0 calc(1 * #{$iphone-sprite-offset});
    }
    &-garage {
      background-position: 0 calc(2 * #{$iphone-sprite-offset});
    }
    &-personal-profile {
      background-position: 0 calc(3 * #{$iphone-sprite-offset});
    }

    @media (max-width: $mobile) {
      &-fast-reg {
        background-position: 0 calc(0 * #{$iphone-sprite-mobile-offset});
      }
      &-geolocation {
        background-position: 0 calc(1 * #{$iphone-sprite-mobile-offset});
      }
      &-garage {
        background-position: 0 calc(2 * #{$iphone-sprite-mobile-offset});
      }
      &-personal-profile {
        background-position: 0 calc(3 * #{$iphone-sprite-mobile-offset});
      }
    }
  }
}